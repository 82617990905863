import React, { useMemo } from "react";
import { css } from "goober";

import { appURLs } from "@/app/constants.mjs";
import { AdPlaceholder, DisplayAd } from "@/feature-ads/AdWrapper.jsx";
import adsRefs from "@/feature-ads/refs.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const useShouldShowInFeedBanner = () => {
  const {
    remoteConfig,
    showInFeedBanner,
    shouldShowAds: shouldShowAdsHandlers,
  } = useSnapshot(adsRefs);

  const canShowBanner = useMemo(() => {
    const shouldShowAds = shouldShowAdsHandlers.every((fn) => fn());
    if (!shouldShowAds || !showInFeedBanner) return false;
    return remoteConfig.adhesion;
  }, [shouldShowAdsHandlers, showInFeedBanner, remoteConfig.adhesion]);

  return [canShowBanner] as const;
};

const InFeedBanner = (): React.ReactNode => {
  const [shouldShowInFeedBanner] = useShouldShowInFeedBanner();

  if (!shouldShowInFeedBanner) return null;

  const msiImage = `${appURLs.CDN}/infeed-images/msi-728x90.webp`;
  const msiLink = `${appURLs.MSI}?utm_source=blitz-app&utm_medium=ad&utm_campaign=blitz-fill-ad`;

  return (
    <DisplayAd
      placeholder={<AdPlaceholder img={msiImage} link={msiLink} />}
      id={"display-desktop-anchor-infeed"}
      className={DisplayAdLeaderboard()}
    />
  );
};
export default InFeedBanner;

export const ProfileTileAd = {
  id: "in-feed-profile-ad",
  priority: 10,
  Component: InFeedBanner,
};

const DisplayAdLeaderboard = () => css`
  --width: 728px;
  height: 90px;
  width: var(--width);
  background: transparent;
  outline: none;

  &:has(iframe) {
    background: var(--shade6);
  }

  .close-btn {
    padding: 0;
    --btn-size: var(--sp-4);
    background: var(--shade10-75);
    position: absolute;
    border-radius: 50%;
    top: calc(-1 * var(--btn-size) - var(--sp-1));
    left: calc(var(--width) - var(--btn-size));
    width: var(--btn-size);
    height: var(--btn-size);
    color: var(--shade0-75);
    cursor: pointer;
    border: 1px solid var(--shade0-50);
  }
  &:not(:has(iframe)) .close-btn {
    display: none;
  }
`;
