import { IS_NODE } from "@/__main__/constants.mjs";
import featureFlags, { allowRead } from "@/__main__/feature-flags.mjs";
import { setFeatureFlag } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import { ProfileTileAd } from "@/feature-ads-infeed-banner/InFeedBanner.jsx";
import matchTileAdRefs from "@/shared/match-tile-ad.refs.mjs";
import { ProfileColumn } from "@/shared/Profile.style.jsx";
import globals from "@/util/global-whitelist.mjs";
import mapOriginalRefs, {
  forceOverwriteIterable,
} from "@/util/map-original-refs.mjs";

const cleanup = {
  removeGlobalStyle: null as (() => void) | null,
};

const originals = mapOriginalRefs({
  adsRefs,
  matchTileAdRefs,
});

const enableInbannerAds = () => {
  const matchTiles = [ProfileTileAd];
  matchTiles[forceOverwriteIterable] = true;

  originals.set({
    adsRefs: {
      showInFeedBanner: true,
    },
    matchTileAdRefs: {
      tiles: matchTiles,
    },
  });

  if (!IS_NODE) {
    const globalStyleElement = globals.document.createElement("style");
    globalStyleElement.innerHTML = `
      @media screen and (min-width: 1240px) {
        .${ProfileColumn({}).props.className}.main {
          min-width: 728px;
        }
      }
    `;
    globals.document.head.appendChild(globalStyleElement);

    cleanup.removeGlobalStyle = () => {
      globalStyleElement.remove();
    };
  }
};

export const setup = () => {
  allowRead();
  if (featureFlags.ads === true) enableInbannerAds();
  // The status of ads will change depending on whether the user is a member, so a dynamic listener is also required
  eventBus.on("adsStatusChange", (enable) => {
    if (enable) {
      enableInbannerAds();
    } else {
      setFeatureFlag("adsInfeedBanner", false);
    }
  });
};

export const teardown = () => {
  originals.restore();

  cleanup.removeGlobalStyle?.();
};
